import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, PositionType } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { Header, HeaderSizes } from "../Header";
import { ToggleWithContent } from "../ToggleWithContent";
import { classNameFactory } from "@src/utils/dom";
import { toggleAutoGainControl, toggleEchoCancellation, toggleNoiseSuppression } from "@src/controller/userMedia";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectStreamSettings } from "@src/selectors";
import "./AudioVideoSettings.mobile.scss";
const cn = classNameFactory("audio-video-settings");
export const AudioVideoSettings = props => {
    const { open = false, onClose } = props;
    const { t } = useTranslation("room");
    const streamSettings = useSubscribe(selectStreamSettings());
    if (!streamSettings)
        return null;
    const { echoCancellation, autoGainControl, noiseSuppression } = streamSettings;
    return (_jsxs(Modal, { position: PositionType.BOTTOM, isOpen: open, hideCloseButton: true, className: cn(""), children: [_jsx(Header, { onClose: onClose, filled: true, isModal: true, marginSize: HeaderSizes.small, children: t("meeting-settings") }), _jsxs("div", { className: cn("content"), children: [_jsx(ToggleWithContent, { label: t("echo_cancellation"), caption: "Malesuada sit tellus ultrices tortor. Mi mauris.", value: echoCancellation, onClick: toggleEchoCancellation }), _jsx(ToggleWithContent, { label: t("auto-gain-control"), caption: "Malesuada sit tellus ultrices tortor. Mi mauris.", value: autoGainControl, onClick: toggleAutoGainControl }), _jsx(ToggleWithContent, { label: t("noise-suppression"), caption: "Malesuada sit tellus ultrices tortor. Mi mauris.", value: noiseSuppression, onClick: toggleNoiseSuppression })] })] }));
};
