import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Loading } from "@ting/ting-ui-components";
import { StreamFrame } from "./StreamFrame";
import { LobbyInfo } from "./LobbyInfo";
import { useLobby } from "./Lobby.hook";
import { InputSettingsRow } from "./InputSettingsRow.mobile";
import { Header, HeaderSizes } from "@src/components/Header";
import { classNameFactory } from "@src/utils/dom";
import "./lobby.mobile.scss";
const cn = classNameFactory("lobby");
export const Lobby = () => {
    const { t } = useTranslation(["common", "room"]);
    const { isJoinMode, isInitializing } = useLobby();
    if (isInitializing)
        return _jsx(Loading, {});
    return (_jsxs("main", { className: cn(""), children: [_jsx(Header, { size: HeaderSizes.medium, marginSize: HeaderSizes.small, className: cn("small-screen-header"), isModal: true, onBack: isJoinMode
                    ? undefined
                    : () => {
                        //TODO: well go back :)
                    }, children: isJoinMode ? t("room:join-a-meeting") : t("common:instant-meeting") }), _jsxs("div", { className: cn("content"), children: [_jsx("div", { className: cn("stream-content"), children: _jsx(StreamFrame, {}) }), _jsx(InputSettingsRow, {}), _jsx(LobbyInfo, {})] })] }));
};
