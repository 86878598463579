import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SpeakerIcon, Text } from "@ting/ting-ui-components";
import { ReverseOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { TimerController } from "@src/components/ControlBar/TimerController";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectRoomTitle } from "@src/selectors";
import { classNameFactory } from "@src/utils/dom";
import "./meetingHeader.mobile.scss";
const cn = classNameFactory("meeting-header");
export const MeetingHeader = () => {
    const roomTitle = useSubscribe(selectRoomTitle());
    return (_jsxs("div", { className: cn(""), children: [_jsxs("div", { className: cn("name-timer"), children: [_jsx(Text, { className: cn("title"), size: "body-large", fontWeight: "semiBold", children: roomTitle }), _jsx(TimerController, {})] }), _jsxs("div", { className: cn("buttons"), children: [_jsx(SpeakerIcon, { width: 24, height: 24 }), _jsx(ReverseOutlinedIcon, { width: 24, height: 24 })] })] }));
};
