import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MeetingHeader } from "./MeetingHeader.mobile";
import { ControlBar } from "@src/components/ControlBar";
import { classNameFactory } from "@src/utils/dom";
import "./meetingLayout.mobile.scss";
const cn = classNameFactory("main");
export const MeetingLayout = props => {
    const { children } = props;
    return (_jsxs("main", { className: cn(""), children: [_jsx(MeetingHeader, {}), _jsx("section", { className: cn("view"), children: children }), _jsx("section", { className: cn("control"), children: _jsx(ControlBar, {}) })] }));
};
