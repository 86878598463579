import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { Button, Modal, PositionType, Text } from "@ting/ting-ui-components";
import { ChevronDownFilledIcon } from "@ting/ting-ui-components/dist/icons";
import { Header, HeaderSizes } from "@src/components/Header";
import { classNameFactory } from "@src/utils/dom";
import "./selectField.scss";
const cn = classNameFactory("select-field");
export const SelectField = props => {
    const { name, options, value, defaultValue, onChange, label, size, className = "" } = props;
    const [isOpenOptionModal, setIsOpenOptionModal] = useState(false);
    const openOptionModal = () => setIsOpenOptionModal(true);
    const closeOptionModal = () => setIsOpenOptionModal(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const onChangeHandler = (name, option) => onChange(option);
    const selectOption = (option) => () => {
        onChangeHandler(name, option);
        closeOptionModal();
    };
    return (_jsxs(Fragment, { children: [_jsx(Text, { size: "p2", fontWeight: "medium", children: label }), _jsx(Button, { v2: true, size: size, endIcon: _jsx(ChevronDownFilledIcon, { width: "24px", height: "24px" }), type: "neutral", className: `${cn("button")} ${className}`.trim(), onClick: openOptionModal, children: value ? value.label : defaultValue?.label }), isOpenOptionModal && (_jsxs(Modal, { position: PositionType.BOTTOM, hideCloseButton: true, onClose: closeOptionModal, isOpen: isOpenOptionModal, children: [_jsx(Header, { isModal: true, size: HeaderSizes.small, filled: true, onClose: closeOptionModal, className: cn("modal-header"), children: label }), _jsx("div", { className: cn("modal-content"), children: options.map(option => (_jsx("button", { className: cn("modal-option"), onClick: selectOption(option), children: _jsx("div", { children: option.label }) }, option.value))) })] }))] }));
};
