import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@ting/ting-ui-components";
import { AccountOutlinedIcon, ChatBubbleOutlinedIcon, FilesOutlinedIcon, InfoOutlinedIcon, MoreHorizontalFilledIcon, ObsOutlinedIcon, PollsOutlinedIcon, ShareOutlinedIcon, } from "@ting/ting-ui-components/dist/icons";
import { BottomModal } from "@src/components/BottomModal";
import { ListRow } from "@src/components/ListRow";
import { StreamController } from "@src/components/ControlBar/StreamController";
import { useNumberOfPeers } from "@src/hooks/useNumberOfPeers";
import { PollModal } from "@src/components/Poll/PollModal";
import { ChatModal } from "@src/components/Chat/ChatModal";
import { useUnreadMessagesCount } from "@src/hooks/useUnreadMessagesCount";
export const OptionController = () => {
    const { t } = useTranslation(["common", "room"]);
    const [modalOpen, setModalOpen] = useState(false);
    const [chatModalOpen, setChatModalOpen] = useState(false);
    const [pollModalOpen, setPollModalOpen] = useState(false);
    const numberOfPeers = useNumberOfPeers();
    const unReadMessagesCount = useUnreadMessagesCount();
    return (_jsxs(Fragment, { children: [_jsx(Button, { v2: true, round: true, onClick: () => setModalOpen(true), icon: _jsx(MoreHorizontalFilledIcon, { height: "20px", width: "20px" }), size: "big", type: "neutral", variant: "primary" }), _jsxs(BottomModal, { isOpen: modalOpen, onClose: () => setModalOpen(false), children: [_jsx(ListRow, { title: t("chat"), icon: ChatBubbleOutlinedIcon, onClick: () => setChatModalOpen(true), badge: unReadMessagesCount > 0 }), _jsx(ListRow, { title: t("polls"), icon: PollsOutlinedIcon, onClick: () => setPollModalOpen(true) }), _jsx(ListRow, { title: t("files"), icon: FilesOutlinedIcon }), _jsx(ListRow, { title: t("room:participants"), icon: AccountOutlinedIcon, indicator: numberOfPeers?.toString() }), _jsx(ListRow, { title: t("room:invite-to-meeting"), icon: ShareOutlinedIcon }), _jsx(ListRow, { title: t("room:meeting-info-and-settings"), icon: InfoOutlinedIcon }), _jsx(ListRow, { title: t("room:stream-meeting"), icon: ObsOutlinedIcon, postElement: _jsx(StreamController, {}) })] }), _jsx(ChatModal, { isOpen: chatModalOpen, onClose: () => setChatModalOpen(false) }), _jsx(PollModal, { isOpen: pollModalOpen, onClose: () => setPollModalOpen(false) })] }));
};
