import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@ting/ting-ui-components";
import { EndCallFilledIcon } from "@ting/ting-ui-components/dist/icons";
import { classNameFactory } from "@src/utils/dom";
import { CamController, HandController, MicController, OptionController } from "@src/components/ControllerButtons";
import { leaveRoom } from "@src/controller";
import { ControllerButtonSizes } from "@src/components/ControllerButtons/types";
import "./meetingController.scss";
const cn = classNameFactory("meeting-controller");
export const MeetingController = () => {
    const handleEndButtonClick = () => {
        leaveRoom();
    };
    return (_jsxs("div", { className: cn(""), children: [_jsx(CamController, { size: ControllerButtonSizes.big }), _jsx(MicController, { size: ControllerButtonSizes.big }), _jsx(HandController, {}), _jsx(OptionController, {}), _jsx(Button, { v2: true, round: true, startIcon: _jsx(EndCallFilledIcon, { height: "20px", width: "20px" }), size: "big", type: "danger", onClick: handleEndButtonClick })] }));
};
